<div>
  <h2> {{ 'Settings::Integration' | abpLocalization }}</h2>
</div>
<br>
<hr>
    <ul ngbNav #nav="ngbNav" [(activeId)]="activeTab" class="nav-tabs">
      <li [ngbNavItem]="1" *ngIf="isGenesysIntegrationActive">
        <a ngbNavLink>
          <span class="ml-1">
            {{ 'Settings::GenesysIntegration' | abpLocalization }}
          </span>
        </a>
        <ng-template ngbNavContent>
          <ca-genesys-integration-settings>
          </ca-genesys-integration-settings>
        </ng-template>
      </li>
      <li [ngbNavItem]="2" *ngIf="isGenesysVTIntegrationActive">
        <a ngbNavLink>
          <span class="ml-1">
            {{ 'Settings::GenesysVTIntegration' | abpLocalization }}
          </span>
        </a>
        <ng-template ngbNavContent>
          <ca-genesys-vt-integration-settings>
          </ca-genesys-vt-integration-settings>
        </ng-template>
      </li>
      <li [ngbNavItem]="3" *ngIf="isZoomMeetingIntegrationActive">
        <a ngbNavLink>
          <span class="ml-1">
            {{ 'Settings::ZoomMeetingIntegration' | abpLocalization }}
          </span>
        </a>
        <ng-template ngbNavContent>
          <ca-zoom-meeting-integration-settings>
          </ca-zoom-meeting-integration-settings>
        </ng-template>
      </li>
      <li [ngbNavItem]="4" *ngIf="isZoomContactCenterIntegrationActive">
        <a ngbNavLink>
          <span class="ml-1">
            {{ 'Settings::ZoomContactCenterIntegration' | abpLocalization }}
          </span>
        </a>
        <ng-template ngbNavContent>
          <ca-zoom-contactcenter-integration-settings>
          </ca-zoom-contactcenter-integration-settings>
        </ng-template>
      </li>
      <li [ngbNavItem]="5" *ngIf="isAloTechIntegrationActive">
        <a ngbNavLink>
          <span class="ml-1">
            {{ 'Settings::AloTechIntegration' | abpLocalization }}
          </span>
        </a>
        <ng-template ngbNavContent>
          <ca-alotech-integration-settings>
          </ca-alotech-integration-settings>
        </ng-template>
      </li>
      <li [ngbNavItem]="6" *ngIf="isPuzzelIntegrationActive">
        <a ngbNavLink>
          <span class="ml-1">
            {{ 'Settings::PuzzelIntegration' | abpLocalization }}
          </span>
        </a>
        <ng-template ngbNavContent>
          <ca-puzzel-integration-settings>
          </ca-puzzel-integration-settings>
        </ng-template>
      </li>
      <li [ngbNavItem]="7" *ngIf="isZendeskIntegrationActive">
        <a ngbNavLink>
          <span class="ml-1">
            {{ 'Settings::ZendeskIntegration' | abpLocalization }}
          </span>
        </a>
        <ng-template ngbNavContent>
          <ca-zendesk-integration-settings>
          </ca-zendesk-integration-settings>
        </ng-template>
      </li>
      <li [ngbNavItem]="8" *ngIf="isAmazonIntegrationActive">
        <a ngbNavLink>
          <span class="ml-1">
            {{ 'Settings::AmazonIntegration' | abpLocalization }}
          </span>
        </a>
        <ng-template ngbNavContent>
          <ca-amazon-integration-settings>
          </ca-amazon-integration-settings>
        </ng-template>
      </li>
      <li [ngbNavItem]="9" *ngIf="isTeamsIntegrationActive">
        <a ngbNavLink>
          <span class="ml-1">
            {{ 'Settings::TeamsIntegration' | abpLocalization }}
          </span>
        </a>
        <ng-template ngbNavContent>
          <ca-teams-integration-settings>
          </ca-teams-integration-settings>
        </ng-template>
      </li>
      <li [ngbNavItem]="10" *ngIf="isFive9IntegrationActive">
        <a ngbNavLink>
          <span class="ml-1">
            {{ 'Settings::Five9Integration' | abpLocalization }}
          </span>
        </a>
        <ng-template ngbNavContent>
          <ca-five9-integration-settings>
          </ca-five9-integration-settings>
        </ng-template>
      </li>
      <li [ngbNavItem]="11" *ngIf="isTwilioIntegrationActive">
        <a ngbNavLink>
          <span class="ml-1">
            {{ 'Settings::TwilioIntegration' | abpLocalization }}
          </span>
        </a>
        <ng-template ngbNavContent>
          <ca-twilio-integration-settings>
          </ca-twilio-integration-settings>
        </ng-template>
      </li>
      <li [ngbNavItem]="12" *ngIf="isAloTechV3IntegrationActive">
        <a ngbNavLink>
          <span class="ml-1">
            {{ 'Settings::AloTechV3Integration' | abpLocalization }}
          </span>
        </a>
        <ng-template ngbNavContent>
          <ca-alotech-v3-integration-settings>
          </ca-alotech-v3-integration-settings>
        </ng-template>
      </li>
    </ul>

    <div [ngbNavOutlet]="nav" class="mt-2" style="height: calc(100% - 130px)">
    </div>
