<form [formGroup]="form" (ngSubmit)="onSubmitForm($event)">
  <div class="form-group pt-2" formGroupName="clientId">
    <label for="clientId">{{ 'Settings::AloTechV3IntegrationClientId' | abpLocalization }}</label>
    <input
      type="text"
      class="form-control form-control-sm"
      id="clientId"
      formControlName="value" />
  </div>
  <div class="form-group pt-2" formGroupName="clientSecret">
    <label for="clientSecret">{{ 'Settings::AloTechV3IntegrationClientSecret' | abpLocalization }}</label>
    <input
      type="text"
      class="form-control form-control-sm"
      id="clientSecret"
      formControlName="value" />
  </div>
  <div class="form-group pt-2" formGroupName="tenantName">
    <label for="tenantName">{{ 'Settings::AloTechV3IntegrationTenantName' | abpLocalization }}</label>
    <input
      type="text"
      class="form-control form-control-sm"
      id="tenantName"
      formControlName="value" />
  </div>
  <div class="form-group pt-2" formGroupName="sourceName">
    <label for="sourceName">{{ 'Settings::AloTechV3IntegrationSourceName' | abpLocalization }}</label>
    <input
      type="text"
      class="form-control form-control-sm"
      id="sourceName"
      formControlName="value" />
  </div>
  <div class="form-group pt-2" formGroupName="migrationStartDate">
    <label for="migrationStartDate">{{ 'Settings::AloTechV3IntegrationMigrationStartDate' | abpLocalization }}</label>
    <input
      type="text"
      placeholder="2023-01-31"
      class="form-control form-control-sm"
      id="migrationStartDate"
      formControlName="value" />
  </div>
  <div class="form-group pt-2" formGroupName="defaultLanguageCode">
    <label for="defaultLanguageCode">{{ 'Settings::AloTechV3IntegrationDefaultLanguageCode' | abpLocalization }}</label>
    <input
      type="text"
      placeholder="en-US"
      class="form-control form-control-sm"
      id="defaultLanguageCode"
      formControlName="value" />
  </div>
  <div class="form-group pt-2" formGroupName="languageCodeTags">
    <label for="languageCodeTags">{{ 'Settings::AloTechV3IntegrationLanguageCodeTags' | abpLocalization }}</label>
    <input
      type="text"
      placeholder='{"turkish":"tr-TR", "english":"en-US"}'
      class="form-control form-control-sm"
      id="languageCodeTags"
      formControlName="value" />
  </div>
  <div class="form-group pt-2" formGroupName="useQueueFilter">
    <input
      type="checkbox"
      id="useQueueFilter"
      (change)="onUseQueueFilterChange($event.currentTarget.checked)"
      [(ngModel)]="useQueueFilterCb"
      formControlName="value"/>
      <label class="ps-1" for="useQueueFilter">
      {{ 'Settings::AloTechV3IntegrationUseQueueFilter' | abpLocalization }}
    </label>
  </div>
  <div class="form-group pt-2" formGroupName="queueFilterDictionary" *ngIf = "form.get('useQueueFilter.value').value">
    <label for="queueFilterDictionary">{{ 'Settings::AloTechV3IntegrationQueueFilterDictionary' | abpLocalization }}</label>
    <input
      type="text"
      class="form-control form-control-sm"
      id="queueFilterDictionary"
      placeholder='{"Queue1Key":"languageCode","Queue2Key":"languageCode"}'
      formControlName="value" />
  </div>
  <div class="form-group pt-2" formGroupName="useAgentFilter">
    <input
      type="checkbox"
      id="useAgentFilter"
      (change)="onUseAgentFilterChange($event.currentTarget.checked)"
      [(ngModel)]="useAgentFilterCb"
      formControlName="value"/>
      <label class="ps-1" for="useAgentFilter">
      {{ 'Settings::AloTechV3IntegrationUseAgentFilter' | abpLocalization }}
    </label>
  </div>
  <div class="form-group pt-2" formGroupName="agentFilterDictionary" *ngIf = "form.get('useAgentFilter.value').value">
    <label for="agentFilterDictionary">{{ 'Settings::AloTechV3IntegrationAgentFilterDictionary' | abpLocalization }}</label>
    <input
      type="text"
      class="form-control form-control-sm"
      id="agentFilterDictionary"
      placeholder='Agent1Key, Agent2Name, Agent3Key'
      formControlName="value" />
  </div>
  <div class="form-group pt-2" formGroupName="useCustomFieldFilter">
    <input
      type="checkbox"
      id="useCustomFieldFilter"
      (change)="onUseCustomFieldFilterChange($event.currentTarget.checked)"
      [(ngModel)]="useCustomFieldFilterCb"
      formControlName="value"/>
      <label class="ps-1" for="useCustomFieldFilter">
      {{ 'Settings::AloTechV3IntegrationUseCustomFieldFilter' | abpLocalization }}
    </label>
  </div>
  <div class="form-group pt-2" formGroupName="customFieldFilterDictionary" *ngIf = "form.get('useCustomFieldFilter.value').value">
    <label for="customFieldFilterDictionary">{{ 'Settings::AloTechV3IntegrationCustomFieldFilterDictionary' | abpLocalization }}</label>
    <input
      type="text"
      class="form-control form-control-sm"
      id="customFieldFilterDictionary"
      placeholder='[{"Level1":"value1","Level2":"value2"}, {"Level1":"value1","Level2":"value2"}]'
      formControlName="value" />
  </div>
  <button type="button" type="submit" class="btn btn-primary mt-3 mb-3" [disabled]="!form.valid">
    <i class="me-1 fas fa-save"></i>{{ 'AbpUi::Save' | abpLocalization }}
  </button>
</form>
