<div>
  <h2>{{ 'Settings::VirtualTranslator' | abpLocalization }}</h2>
</div>
<br />
<hr />
<form [formGroup]="form" (ngSubmit)="onSubmitForm($event)">
  <div class="form-group pt-2">
    <label class="form-label" for="service">
      {{ 'Settings::VirtualTranslator:Service' | abpLocalization }}
    </label>
    <ca-select formControlName="service" class="below-all" (change)="onTypeChange($event)">
      <ca-option *ngFor="let serviceType of serviceTypes" [value]="serviceType.code">
        {{ 'GenericLookup::' + serviceType.code | abpLocalization }}
      </ca-option>
    </ca-select>
  </div>
  <div *ngIf="isServiceSelected('VirtualTranslator.Service.KnovvuIVR')" class="form-group pt-2" formGroupName="endpoint">
    <label class="indicates-required-field" for="endpoint">
      {{ 'Settings::VirtualTranslator:Endpoint' | abpLocalization }}
    </label>
    <input type="text" class="form-control form-control-sm" id="endpoint" formControlName="value" />
  </div>
  <button type="button" type="submit" class="btn btn-primary mt-3 mb-3" [disabled]="!form.valid">
    <i class="me-1 fas fa-save"></i>
    {{ 'AbpUi::Save' | abpLocalization }}
  </button>
</form>
