export class RoleConstants {
  static readonly admin = 'admin';
  static readonly tenantAdmin = 'tenantadmin';
  static readonly agent = 'Agent';
  static readonly evaluator = 'Evaluator';
  static readonly supervisor = 'Supervisor';

  static readonly adminNormalizedValue = 'ADMIN';
  static readonly tenantAdminNormalizedValue = 'TENANTADMIN';
  static readonly agentNormalizedValue = 'AGENT';
  static readonly evaluatorNormalizedValue = 'EVALUATOR';
  static readonly supervisorNormalizedValue = 'SUPERVISOR';
}
