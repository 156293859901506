import { ConfigStateService, LocalizationService } from '@abp/ng.core';
import { ToasterService } from '@abp/ng.theme.shared';
import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ConfigurationSettingsDto } from 'src/core/models/configuration-setting/configuration-settings.dto';
import { ConfigurationSettingsService } from 'src/core/services/configuration-settings/configuration-settings.service';
import { StringValidator } from 'src/core/validators/shared/string.validator';

@Component({
  selector: 'ca-genesys-vt-integration-settings',
  templateUrl: './genesys-vt-integration-settings.component.html',
  styleUrls: ['./genesys-vt-integration-settings.component.scss'],
})
export class GenesysVTIntegrationSettingsComponent implements OnInit {
  form: FormGroup;

  private readonly prefix = 'GenesysVTIntegration';
  private readonly clientId = `${this.prefix}.ClientId`;
  private readonly clientSecret = `${this.prefix}.ClientSecret`;
  private readonly region = `${this.prefix}.Region`;
  private readonly groupId = `${this.prefix}.GroupId`;
  
  useQueueFilterCb = false;
  chatIntegrationEnabledCb = false;

  constructor(
    private fb: FormBuilder,
    private config: ConfigStateService,
    private settingService: ConfigurationSettingsService,
    private toastr: ToasterService,
    private localizationService: LocalizationService
  ) {

    this.form = this.fb.group({
      clientId: this.fb.group({
        name: this.clientId,
        value: [
          this.config.getSetting(this.clientId),
          {
            validators: [Validators.required, StringValidator.whitespace],
          },
        ],
      }),
      clientSecret: this.fb.group({
        name: this.clientSecret,
        value: [
          this.config.getSetting(this.clientSecret),
          {
            validators: [StringValidator.whitespace],
          },
        ],
      }),
      region: this.fb.group({
        name: this.region,
        value: [
          this.config.getSetting(this.region),
          {
            validators: [Validators.required, StringValidator.whitespace],
          },
        ],
      }),
      groupId: this.fb.group({
        name: this.groupId,
        value: [
          this.config.getSetting(this.groupId),
          {
            validators: [Validators.required, StringValidator.whitespace],
          },
        ],
      }),
    });
  }

  ngOnInit(): void {}

  onSubmitForm(eventArgs): void {
    if (!this.form.valid) {
      return;
    }

    const settings: ConfigurationSettingsDto[] = [];

    settings.push({
      settingName: this.form.get('clientId').get('name').value,
      settingValue: this.form.get('clientId').get('value').value,
    });

    if (this.form.get('clientSecret').get('value').value) {
      settings.push({
        settingName: this.form.get('clientSecret').get('name').value,
        settingValue: this.form.get('clientSecret').get('value').value,
      });
    }

    settings.push({
      settingName: this.form.get('region').get('name').value,
      settingValue: this.form.get('region').get('value').value,
    });

    settings.push({
      settingName: this.form.get('groupId').get('name').value,
      settingValue: this.form.get('groupId').get('value').value,
    });

    this.settingService.saveSetting(settings).subscribe(res => {
      this.toastr.success(
        this.localizationService.instant('AbpSettingManagement::SuccessfullySaved')
      );
    });
  }
}
