import { GenericLookupType } from 'src/core/decorators/generic-lookup/generic-lookup-type.decorator';
import { GenericLookup } from 'src/core/decorators/generic-lookup/generic-lookup.decorator';

@GenericLookupType({
    code: 'VirtualTranslator.Service'
})
export class VirtualTranslatorService {
    @GenericLookup({
        code: 'VirtualTranslator.Service.KnovvuIVR'
    })
    static knovvuIVR: string;

    @GenericLookup({
        code: 'VirtualTranslator.Service.KnovvuCore'
    })
    static knovvuCore: string;
}
